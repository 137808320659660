const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://cryptoarm.ru/',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://ftp.digt.ru/web/static/cryptogost.svg',
    logoLink: 'https://cryptoarm.ru//',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `<li>
        <a href="https://vk.com/cryptoarm" target="_blank" rel="noopener">
          <div class="twitterBtn">
            <img src='https://cdn-icons-png.flaticon.com/512/25/25684.png' alt={'ВКонтакте'}/>
          </div>
        </a>
      </li><li>
        <a href="https://t.me/cryptoarm" target="_blank" rel="noopener">
          <div class="twitterBtn">
            <img src='https://cdn-icons-png.flaticon.com/512/2111/2111708.png' alt={'Телеграм'}/>
          </div>
        </a>
      </li><li>
        <a href="https://www.youtube.com/channel/UC0OiDEwZV3k3jchGSd-NECQ" target="_blank" rel="noopener">
          <div class="twitterBtn">
            <img src='https://cdn-icons-png.flaticon.com/512/1384/1384028.png' alt={'YouTube'}/>
          </div>
        </a>
      </li><li>
        <a href="https://zen.yandex.ru/id/595ceed78146c14bcd821b8e" target="_blank" rel="noopener">
          <div class="twitterBtn">
            <img src='https://cdn-icons-png.flaticon.com/512/152/152840.png' alt={'Яндекс.Дзен'}/>
          </div>
        </a>
      </li><li>
        <a href="https://rutube.ru/channel/23839307/" target="_blank" rel="noopener">
          <div class="twitterBtn">
            <img src='https://cdn-icons-png.flaticon.com/512/3522/3522624.png' alt={'Rutube'}/>
          </div>
        </a>
      </li>`,
    links: [{ text: '', link: '' }],
    // search: {
    //  enabled: true,
    //  indexName: 'Crgost',
    //  algoliaAppId: "YSUPZ6O4QF",
    //  algoliaSearchKey: "a9c5865fedb6fa111eba7be1fffb6ac8",
    //  algoliaAdminKey: "856f676628f9f739513bb0757591b2cc",
    // },
  },
  sidebar: {
    forcedNavOrder: ['v2.5.11','v3.2','v3.2.9','v3.3','v3.7','v3.8','v3.9','01-v0.7','02-v3.0','03-v3.0'],
    collapsedNav: [],
    links: [{ text: 'КриптоАРМ ГОСТ', link: 'https://cryptoarm.ru/' }],
    frontline: false,
    ignoreIndex: true,
    title:
      "<a href='https://cryptoarm.ru/'>КриптоАРМ ГОСТ </a>",
  },
  siteMetadata: {
    title: 'GitDocs CRYPTO.GOST',
    description: 'Documentation built with mdx.',
    ogImage: null,
    docsLocation: '',
    favicon: 'https://ftp.digt.ru/web/static/cryptogost.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'GitDocs CRYPTO.GOST',
      short_name: 'GitDocs CRYPTO.GOST',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
